import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/fake",
    name: "fake",
    component: () => import("../views/Fake"),
  },
  {
    path: "/congratulations",
    name: "Congratulations",
    component: () => import("@/views/Congratulations"),
  },
  {
    path: "/member",
    component: () => import("@/views/Member"),
    children: [
      { path: "", redirect: "home" },
      {
        path: "home",
        component: () => import("@/views/member/Index"),
      },
      {
        path: "login",
        name: "memberLogin",
        component: () => import("@/views/member/Login"),
      },
      {
        path: "register",
        name: "memberRegister",
        component: () => import("@/views/member/Register"),
      },
      {
        path: "cart",
        name: "Cart",
        component: () => import("@/views/member/Cart"),
      },
      {
        path: "detail/:code",
        name: "Detail",
        component: () => import("@/views/member/Detail"),
      },
      {
        path: "orders",
        name: "MemberOrder",
        component: () => import("../views/member/Orders"),
      },
      {
        path: "products",
        component: () => import("../views/member/Products"),
      },
      {
        path: "profile",
        name: "MemberProfile",
        component: () => import("@/views/member/Profile"),
      },
      {
        path: "updateProfile",
        component: () => import("@/views/member/UpdateProfile"),
      },
      {
        path: "changePassword",
        component: () => import("@/views/member/ChangePassword"),
      },
      {
        path: "pointsRecord",
        component: () => import("@/views/member/PointsRecord"),
      },
      {
        path: "forgotPassword",
        component: () => import("@/views/member/ForgotPassword"),
      },
      {
        path: "codeCheckResult",
        component: () => import("@/views/member/CodeCheckResult"),
      },
      {
        path: "resetPassword",
        component: () => import("@/views/ResetPassword"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/member",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

export default router;
