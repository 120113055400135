import Vue from "vue";
import VueI18n from "vue-i18n";
import zhCN from "./zh-CN";
import enUS from "./en-US";
import esES from "./es-ES.json";
import Cookies from "js-cookie";
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import esLocale from 'element-ui/lib/locale/lang/es'

Vue.use(VueI18n);
Vue.locale = () => {};

const locale = Cookies.get("lang") || 'en_US';
export const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "en_US",
  messages: { "zh_CN": Object.assign(zhCN,zhLocale), "en_US": Object.assign(enUS,enLocale),"es-ES": Object.assign(esES,esLocale),},
  silentTranslationWarn: true,
});

// 切换中英文调用该方法
export function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  Cookies.set("lang", lang);
  document.title = i18n.t("title")
  return lang;
}

export function getLanguage(){
  return i18n.locale
}