import "./assets/theme/index.css";
import "./assets/scss/index.scss";
import "./assets/scss/global.scss";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { i18n,setI18nLanguage } from "./lang/index";

Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
});

import { getToken } from "@/utils/auth";
router.beforeEach((to, from, next) => {
  if (getToken()) {
    store
      .dispatch("GetInfo", true)
      .then((res) => {
        store.dispatch("member/loadCartProducts", true);
        if (to.path === "/member/login") {
          next("/");
        } else {
          next();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    next();
  }
});

Vue.prototype.$image = (url) => {
  // return process.env.VUE_APP_BASE_API + url;
  return `https://mall.qqdf.com/prod-api/${url}`;
};
Vue.prototype.setI18nLanguage = setI18nLanguage;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
