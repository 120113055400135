import { addOrder, getTopProducts } from "../api/special";
import { addCart, listCart, updateCart } from "../api/mall/cart";

export const state = () => ({
  cart: {
    products: [],
  },

  homeProducts: [],
  isLoaded:false
});

export const getters = {
  cartProductCount: (state) => state.cart.products.length,
  cartProducts: (state) => state.cart.products,
  getHomeProducts: (state) => state.homeProducts,
};

export const mutations = {
  addToCart(state, payloay) {
    const prodcut = state.cart.products.find(
      (a) => a.productId == payloay.productId
    );
    if (prodcut) {
      prodcut.count += 1;
      return;
    }

    state.cart.products.push({
      productId: payloay.productId,
      product: payloay,
      count: 1,
    });
  },
  cartIncrease(state, productId) {
    let prodcut = state.cart.products.find((a) => a.productId == productId);
    prodcut.count += 1;
  },
  cartReduced(state, productId) {
    let prodcut = state.cart.products.find((a) => a.productId == productId);
    prodcut.count -= 1;
    if (prodcut.count <= 0) {
      const index = state.cart.products.findIndex(
        (a) => a.productId == productId
      );
      state.cart.products.splice(index, 1);
    }
  },

  clearCart(state) {
    state.cart.products = [];
  },

  deleteProduct(state, productId) {
    const index = state.cart.products.findIndex(
      (a) => a.productId == productId
    );
    state.cart.products.splice(index, 1);
  },

  SET_MEMBER(state, memberInfo) {
    state.memberInfo = memberInfo;
  },

  SET_HOMEPRODUCTS(state, homeProducts) {
    state.homeProducts = homeProducts;
  },
  SET_CARTS(state, products) {
    state.cart.products = products;
  },
  SET_LOADED(state){
    state.isLoaded = true
  }
};

export const actions = {
  submitOrder({ state }, { store, cart, total, userid }) {
    return new Promise((resolve, reject) => {
      addOrder({
        storeId: store.storeId,
        total: total,
        cartIds: cart.filter(a=>a.checked).map(a=>a.cartId)
        // products: cart.map((a) => {
        //   return {
        //     productId: a.productId,
        //     count: a.count,
        //   };
        // }),
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  loadHomeProducts({ state, commit }) {
    if (state.homeProducts.length > 0) {
      return;
    }
    getTopProducts().then((res) => {
      commit("SET_HOMEPRODUCTS", res.data);
    });
  },

  loadCartProducts({ state, commit }, initLoad) {
    if(state.isLoaded && initLoad){
      return
    }
    listCart({ pageSize: 30 }).then((res) => {
      commit("SET_CARTS", res.rows);
      commit("SET_LOADED",true)
    });
  },

  addCartProduct({ state, dispatch }, product) {
    let existProdcut = state.cart.products.find(
      (a) => a.productId == product.productId
    );
    if (existProdcut) {
      existProdcut.count+=1;
      updateCart(existProdcut)
      return;
    }

    const params = {
      pageNum: 1,
      pageSize: 10,
      productId: product.productId,
      productname: product.productName,
      producturl: product.productUrl,
      count: 1,
      addtime: null,
      checked: 1,
    };

    addCart(params).then((res) => {
      if (res.code == 200) {
        dispatch("loadCartProducts");
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
