import Cookies from 'js-cookie'
import {queryDict} from '../../api/special'
const state = {
  showBottomNav:false,
  device: 'desktop',
  size: Cookies.get('size') || 'medium'
}

const mutations = {
  SET_BottomNav_Visible(state,visible){
      state.showBottomNav = visible
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_SIDEBAR_HIDE: (state, status) => {
    state.sidebar.hide = status
  }

  
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  toggleSideBarHide({ commit }, status) {
    commit('SET_SIDEBAR_HIDE', status)
  },
  async getDict({},dictType){
    return await queryDict(dictType)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
